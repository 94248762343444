import { Component, OnInit } from '@angular/core';
import { IFileSettings } from 'src/app/interfaces/i-file-settings';
import { FileService } from 'src/app/services/file.service';
import { MappingService } from 'src/app/services/mapping.service';
import { OntologyService } from 'src/app/services/ontology.service';

@Component({
  selector: 'app-ontology-to-ontology-data-input',
  templateUrl: './ontology-to-ontology-data-input.component.html',
  styleUrls: ['./ontology-to-ontology-data-input.component.scss'],
})
export class OntologyToOntologyDataInputComponent implements OnInit {
  public error: string | undefined;
  public readonly MAX_ALLOWED_SIZE_MB = 5;
  public MAX_UNIQUE_TERMS: number;
  public uploadingFile = false;
  sourceOntology: string | undefined;
  selectSourceSectionCollapsed = false;
  uploadCsvSectionCollapsed = true;
  selectIriColumnSectionCollapsed = true;

  public csvColumns: string[] = [];
  public iriColumn: string | undefined;

  constructor(
    private fileService: FileService,
    public mappingService: MappingService,
    public ontologyService: OntologyService
  ) {
    this.MAX_UNIQUE_TERMS = MappingService.MAX_UNIQUE_TERMS_FOR_COLUMN_MAPPING;
  }

  ngOnInit() {
    this.loadColumnNames();
    this.sourceOntology = this.mappingService.ontologyToOntologySettings?.source;
    this.iriColumn = this.mappingService.ontologyToOntologySettings?.iriColumn;
  }

  async onFileSelected(file: File) {
    this.error = undefined;
    if (file.size > this.MAX_ALLOWED_SIZE_MB * 1024 * 1024) {
      this.error = `File size is too large. File must be less than ${this.MAX_ALLOWED_SIZE_MB}MB`;
    } else {
      this.uploadingFile = true;
      const fileData = await this.fileService.parseCsv(file).catch(error => (this.error = error));
      if (fileData.data.length === 0) {
        this.error = 'Unable to load columns. No columns found';
      } else {
        this.mappingService.uploadFile(file).subscribe({
          next: result => {
            const fileSettings: IFileSettings = {
              fileData,
              fileName: result.file_name,
              fileUrl: result.file_url_path,
            };
            this.mappingService.fileSettings = fileSettings;
          },
          complete: () => {
            this.uploadingFile = false;
            this.onFileUploadChange();
          },
        });
      }
    }
  }

  onSourceOntologyChange() {
    if (this.sourceOntology) {
      this.uploadCsvSectionCollapsed = false;
      this.selectSourceSectionCollapsed = true;
      this.selectIriColumnSectionCollapsed = true;
    } else {
      this.uploadCsvSectionCollapsed = true;
      this.selectSourceSectionCollapsed = false;
      this.selectIriColumnSectionCollapsed = true;
    }
    this.saveSettings();
  }

  onFileUploadChange() {
    this.csvColumns = [];
    this.iriColumn = undefined;
    this.loadColumnNames();
    if (this.mappingService.fileSettings.fileData) {
      this.uploadCsvSectionCollapsed = true;
      this.selectSourceSectionCollapsed = true;
      this.selectIriColumnSectionCollapsed = false;
    }
    this.saveSettings();
  }

  onIriColumnChange() {
    if (this.iriColumn) {
      this.uploadCsvSectionCollapsed = true;
      this.selectSourceSectionCollapsed = true;
      this.selectIriColumnSectionCollapsed = true;
    }
    this.saveSettings();
  }

  private loadColumnNames() {
    this.mappingService.fileSettings.fileData?.data.forEach(column => {
      this.csvColumns.push(column.columnHeader);
    });
    this.iriColumn = undefined;
  }

  private saveSettings() {
    if (this.sourceOntology) {
      this.mappingService.ontologyToOntologySettings = {
        source: this.sourceOntology,
      };
      if (this.mappingService.fileSettings.fileUrl && this.iriColumn) {
        this.mappingService.ontologyToOntologySettings = {
          source: this.sourceOntology,
          iriColumn: this.iriColumn,
          fileUrl: this.mappingService.fileSettings.fileUrl,
        };
      }
    } else {
      this.mappingService.ontologyToOntologySettings = undefined;
    }
  }
}
