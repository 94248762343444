<div class="flex-y row-gap-1 width-full">
  <div class="grid-3-columns column-gap-1 row-gap-1 margin-inline-auto margin-top-1">
    <div class="border-radius-1 light-gray-fill padding-1">
      <div class="text-center margin-bottom-1">
        <button nbButton status="primary" shape="round" (click)="mapIndividualTerms()">Map Individual Terms</button>
      </div>
      <app-individual-terms-graphic></app-individual-terms-graphic>
    </div>
    <div class="border-radius-1 light-gray-fill padding-1">
      <div class="text-center margin-bottom-1">
        <button nbButton status="primary" shape="round" (click)="uploadFile()">Column Mapping</button>
      </div>
      <app-column-mapping-graphic></app-column-mapping-graphic>
    </div>
    <div class="border-radius-1 light-gray-fill padding-1">
      <div class="text-center margin-bottom-1">
        <button nbButton status="primary" shape="round" (click)="mapOntologyTermsToOntology()">
          Map Ontology to Ontology
        </button>
      </div>
      <app-map-ontology-graphic></app-map-ontology-graphic>
    </div>
    <div>
      <div class="text-center width-4">Have a list of terms that need to be mapped to ontologies? Start here.</div>
    </div>
    <div>
      <div class="text-center width-4">
        Upload a 2D file and the terms in each column are mapped to an ontology of your choice. That simple.
      </div>
    </div>
    <div>
      <div class="text-center width-4">Want to map a list of ontology terms to another ontology? Click here.</div>
    </div>
  </div>
</div>
