import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { MappingType } from 'src/app/enums/mapping-type';
import { IIncludeItem } from 'src/app/interfaces/i-data-settings';
import { IMappingMethodology } from 'src/app/interfaces/i-mapping-methodology';
import { ApiService } from 'src/app/services/api.service';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-download-dialog',
  templateUrl: './download-dialog.component.html',
  styleUrls: ['./download-dialog.component.scss'],
})
export class DownloadDialogComponent implements OnInit, OnDestroy {
  @Input() taskId!: string;
  @Input() inputType!: MappingType;
  @Input() mappingMethodology!: IMappingMethodology;
  @Input() totalTerms!: number;
  @Input() fileName?: string | undefined;

  loading = false;
  columns: IIncludeItem[] = [];
  allColumnsChecked = true;
  fileFormat = 'csv';
  multipleFileFormats!: boolean;
  downloadSuccess = false;

  error: string | undefined;

  private subscription: Subscription | undefined;

  constructor(
    private dialogRef: NbDialogRef<unknown>,
    private api: ApiService,
    private mappingService: MappingService
  ) {}

  ngOnInit() {
    this.multipleFileFormats = this.inputType === MappingType.INDIVIDUAL_TERMS;
    this.subscription = this.mappingService.initialized$.subscribe({
      next: initialized => {
        this.loading = !initialized;
        if (initialized) {
          this.loadPage();
        }
      },
      error: err => {
        this.loading = false;
        this.error = err;
      },
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private loadPage() {
    this.columns = this.mappingService.getIncludeItems(this.mappingMethodology, this.inputType);
  }

  onColumnsUpdate(value?: boolean) {
    this.downloadSuccess = false;
    if (value === false) {
      this.allColumnsChecked = false;
    }
    if (this.allColumnsChecked) {
      this.columns.forEach(item => {
        item.included = true;
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

  async download() {
    this.downloadSuccess = false;
    this.loading = true;
    const fileName = this.fileName ? this.fileName : this.taskId;
    const headers: string[] = [];
    this.columns.forEach(column => {
      if (column.included) headers.push(column.label);
    });
    switch (this.inputType) {
      case MappingType.INDIVIDUAL_TERMS: {
        await this.api
          .download(this.taskId, fileName, headers, this.fileFormat)
          .then(() => {
            this.setDownloadSuccess();
          })
          .catch(err => (this.error = err));
        break;
      }
      case MappingType.UPLOAD_FILE: {
        await this.api
          .downloadTermsMappedFile(this.taskId, fileName, headers)
          .then(() => {
            this.setDownloadSuccess();
          })
          .catch(err => (this.error = err));
        break;
      }
      case MappingType.ONTOLOGY_TO_ONTOLOGY: {
        await this.api
          .downloadOntologyToOntologyResultsFile(this.taskId, fileName)
          .then(() => {
            this.setDownloadSuccess();
          })
          .catch(err => (this.error = err));
        break;
      }
    }
    this.loading = false;
  }

  private setDownloadSuccess() {
    this.downloadSuccess = true;
  }
}
