<div class="width-5">
  <igx-grid
    [data]="taskErrors"
    [autoGenerate]="false"
    [height]="null"
    [emptyGridMessage]="
      loading
        ? ' '
        : error
        ? '❌ Error: ' + error + '. Please try again later.'
        : 'No Errors were detected during this Mapping Task'
    "
    [isLoading]="loading">
    <igx-grid-toolbar>
      <igx-grid-toolbar-title>
        <b>Mapping Errors ⚠️ </b>
      </igx-grid-toolbar-title>
    </igx-grid-toolbar>
    <igx-paginator [perPage]="5" [selectOptions]="[5, 10]"> </igx-paginator>
    <igx-column field="term" header="Term, Ontology">
      <ng-template igxCell let-val let-cell="cell">
        <div class="flex-y">
          <div>
            <b>
              {{ val }}
            </b>
          </div>
          <div>{{ cell?.row?.data?.ontology }}</div>
        </div>
      </ng-template>
    </igx-column>
    <igx-column field="msg" header="Error Message">
      <ng-template igxCell let-val>{{ val }}</ng-template>
    </igx-column>
  </igx-grid>
</div>
