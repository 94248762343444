<ng-container *ngIf="!mappingError">
  <ng-container *ngIf="!ready">
    <nb-spinner *ngIf="startingMapping" status="primary" message="Starting Mapping ..."></nb-spinner>
    <div *ngIf="!startingMapping">
      <div class="width-5 margin-inline-auto">
        <div class="margin-top-2 text-center">Mapping In Progress. Please Wait...</div>
        <nb-progress-bar [value]="percentageComplete" status="primary" [displayValue]="true"></nb-progress-bar>
        <div class="margin-inline-auto margin-top-2 width-fit">
          <button nbButton shape="round" status="primary" (click)="cancelTask()" [nbSpinner]="cancelling">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="ready">
    <div *ngIf="queriesFailed" class="text-center">
      <b>⚠️ Warning:</b> Some mappings failed
      <span>
        <button nbButton size="small" status="primary" outline (click)="viewTaskErrors()" shape="round">
          View Errors<nb-icon icon="alert-triangle-outline"></nb-icon>
        </button>
      </span>
    </div>
    <igx-grid
      *ngIf="gridData !== undefined"
      [isLoading]="loadingResults"
      [emptyGridMessage]="loadingResults ? 'Loading Preview ...' : 'No Mappings found'"
      [data]="gridData"
      [autoGenerate]="false"
      class="margin-top-1"
      [height]="null"
      [allowFiltering]="true"
      (sortingDone)="onSortingDone($event)">
      <igx-grid-toolbar>
        <igx-grid-toolbar-title>Terminology Mappings Preview</igx-grid-toolbar-title>
        <igx-grid-toolbar-actions>
          <igx-grid-toolbar-hiding title="Column Hiding"></igx-grid-toolbar-hiding>
        </igx-grid-toolbar-actions>
      </igx-grid-toolbar>
      <ng-container *ngFor="let header of results?.header">
        <igx-column
          *ngIf="!['term_id', 'mapping_rule_id'].includes(header)"
          [field]="header"
          [header]="header | titlecase"
          [resizable]="true"
          [sortable]="true">
          <ng-template *ngIf="header === 'iri'" igxCell let-cell="cell">
            <a [href]="cell.value" target="_blank">{{ cell.value }}</a>
          </ng-template>
          <ng-template *ngIf="header === 'label'" igxCell let-cell="cell">
            <div class="flex-between width-full column-gap-1">
              <div class="margin-block-auto">
                {{ cell.value }}
              </div>
              <div class="margin-block-auto">
                <button
                  *ngIf="cell.row.data['similarity score']?.toString().includes(userMapLabel)"
                  nbButton
                  status="success"
                  shape="round"
                  size="small"
                  (click)="deleteMappingRule(cell.row.data)">
                  <nb-icon icon="checkmark-outline"></nb-icon>
                </button>
                <button
                  *ngIf="canAddMappingRule(cell.row.data)"
                  nbButton
                  status="primary"
                  shape="round"
                  size="small"
                  (click)="addMappingRule(cell.row.data)">
                  <nb-icon icon="swap-outline"></nb-icon>
                </button>
              </div>
            </div>
          </ng-template>
        </igx-column>
      </ng-container>
      <igx-paginator></igx-paginator>
    </igx-grid>
    <div *ngIf="!loadingResults" class="margin-inline-auto margin-top-1 width-fit">
      <button nbButton shape="round" status="primary" (click)="downloadResults()">Download Results</button>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="mappingError">
  <div class="text-center margin-top-1">
    <div class="header-1">❌</div>
    Something went wrong.<br />
    Please try again later.<br />
    Error: {{ mappingError }}
  </div>
</ng-container>
