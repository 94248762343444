import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MappingService } from '../../services/mapping.service';
import { NbStepperComponent } from '@nebular/theme';
import { MappingSteps } from '../../enums/mapping-steps';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, firstValueFrom } from 'rxjs';
import { ReRunMappingService } from 'src/app/services/re-run-mapping.service';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-new-mapping',
  templateUrl: './new-mapping.component.html',
  styleUrls: ['./new-mapping.component.scss'],
})
export class NewMappingComponent implements OnInit, OnDestroy {
  public stepIndex: MappingSteps = MappingSteps.Input_Type;
  public MappingSteps = MappingSteps;
  public error: string | undefined;
  public mappingComplete = false;
  public ready = false;
  public rerunUnavailableOntologies: string | undefined;
  public reRunSourceOntologyUnavailable: string | undefined;

  public initError: string | undefined;

  private subscriptions: Subscription[] = [];

  @ViewChild('stepper') stepper: NbStepperComponent | undefined;

  constructor(
    public mappingService: MappingService,
    private reRunMappingService: ReRunMappingService,
    private router: Router,
    private route: ActivatedRoute,
    private analytics: AnalyticsService,
    private title: Title
  ) {}

  ngOnInit() {
    this.mappingService.reset();
    const sub1 = this.mappingService.initialized$.subscribe({
      next: async initialized => {
        if (initialized) {
          const requestUrl = this.route.snapshot.paramMap.get('request-url');
          if (requestUrl) {
            this.rerunUnavailableOntologies = undefined;
            const mapping = await firstValueFrom(this.mappingService.getTaskRequest(requestUrl));
            if (mapping) {
              await this.reRunMappingService.loadSettings(requestUrl, mapping);
              const unavailableOntologies = this.reRunMappingService.allUnavailableOntologies;
              if (unavailableOntologies.length > 0) {
                this.rerunUnavailableOntologies = unavailableOntologies.join(', ');
              }
              this.reRunSourceOntologyUnavailable = this.reRunMappingService.sourceOntologyUnavailable;
              this.stepIndex = MappingSteps.Data_Input;
            }
            this.ready = true;
          } else {
            this.ready = true;
          }
          this.updateTitle();
        }
      },
      error: err => {
        this.initError = err;
        console.error(err);
      },
    });
    this.subscriptions.push(sub1);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  private updateTitle() {
    this.title.setTitle('New Mapping | ' + MappingSteps[this.stepIndex]);
  }

  validate(): boolean {
    const stepValidor = this.mappingService.isStepValid(this.stepIndex);
    if (stepValidor.dataVaild) {
      this.error = undefined;
    } else {
      this.error = stepValidor.error;
    }
    return stepValidor.dataVaild;
  }

  nextStep() {
    if (this.validate()) {
      this.analytics.trackEvent(
        'new_mapping_next_step_' + MappingSteps[this.stepIndex] + '_click',
        'User Clicked on NEXT during mapping step = ' + MappingSteps[this.stepIndex],
        'new-mapping-step'
      );
      this.stepIndex++;
      this.updateTitle();
    }
  }

  prevStep(clickAction = true) {
    if (clickAction) {
      this.analytics.trackEvent(
        'new_mapping_previous_step_' + MappingSteps[this.stepIndex] + '_click',
        'User Clicked on BACK during mapping step = ' + MappingSteps[this.stepIndex],
        'new-mapping-step'
      );
    }
    if (this.stepIndex > 0) {
      this.error = undefined;
      this.mappingComplete = false;
      this.stepIndex--;
    } else {
      this.router.navigate(['/']);
    }
    this.updateTitle();
  }

  getStepCompleted(index: number) {
    return {
      valid: this.stepIndex >= index,
      reset: () => console.log('Mapping Step ' + index + ' reset'),
    };
  }

  onMappingCancelled(cancelled: boolean) {
    if (cancelled) {
      this.analytics.trackEvent(
        'new_mapping_cancel_click',
        'User Clicked on CANCEL during mapping',
        'new-mapping-step'
      );
      this.prevStep(false);
    }
  }

  onMappingComplete(completed: boolean) {
    if (completed) {
      this.mappingComplete = true;
    } else {
      this.mappingComplete = false;
    }
  }

  reset() {
    this.analytics.trackEvent(
      'new_mapping_result_start_over_click',
      'User Clicked on START OVER after seeing mapping results',
      'new-mapping-result'
    );
    this.stepper?.reset();
    this.stepIndex = 0;
    this.mappingComplete = false;
    this.mappingService.reset();
    this.router.navigate(['new-mapping']);
    this.updateTitle();
  }

  refreshPage() {
    location.reload();
  }
}
