import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-unknown-error',
  templateUrl: './unknown-error.component.html',
  styleUrls: ['./unknown-error.component.scss'],
})
export class UnknownErrorComponent implements OnInit {
  errorMsg: string | undefined;
  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.errorMsg = params['errorMsg'];
    });
  }

  navigateHome() {
    this.router.navigate(['/']).then(() => location.reload());
  }
}
