<div *ngIf="!error">
  <igx-grid
    [data]="ontologies ? ontologies : []"
    [autoGenerate]="false"
    [height]="null"
    emptyGridMessage=" "
    [isLoading]="loading"
    [allowFiltering]="true">
    <igx-grid-toolbar>
      <igx-grid-toolbar-title><b>Ontologies Available</b></igx-grid-toolbar-title>
    </igx-grid-toolbar>
    <igx-paginator></igx-paginator>
    <igx-column field="ontology_name" header="Ontology Name" [resizable]="true" [sortable]="true"></igx-column>
    <igx-column field="ontology_version" header="Version" [resizable]="true" [sortable]="true"></igx-column>
    <igx-column [hidden]="true" field="ontology_id"></igx-column>
    <igx-column field="visibility" header="Visibility" [resizable]="true" [sortable]="true"></igx-column>
    <igx-column
      field="updated_at"
      header="Last Updated"
      [resizable]="true"
      dataType="date"
      [sortable]="true"></igx-column>
    <igx-column field="created_by" header="Created By" [resizable]="true" [sortable]="true"></igx-column>
    <igx-column field="methodsList" header="Methods Available" [resizable]="true" [filterable]="true" [sortable]="true">
    </igx-column>
    <igx-column header="Actions" [minWidth]="'200px'" [filterable]="false">
      <ng-template igxCell let-cell="cell">
        <div class="flex-x column-gap-1">
          <button
            *ngIf="cell.row.data.top_level_parents_count && cell.row.data.top_level_parents_count > 0"
            nbButton
            nbTooltip="View"
            nbTooltipPlacement="bottom"
            status="primary"
            outline
            shape="round"
            [routerLink]="cell.row.data.ontology_id">
            <nb-icon icon="eye-outline" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
          </button>
          <button
            [hidden]="cell.row.data.methodsList?.toUpperCase().includes('NONE')"
            nbButton
            status="primary"
            nbTooltip="Mapping Rules"
            nbTooltipPlacement="bottom"
            outline
            shape="round"
            [routerLink]="cell.row.data.ontology_id + '/mapping-rules'">
            <nb-icon icon="swap-outline" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
          </button>
          <button
            [hidden]="!keycloakService.canManageOntology(cell.row.data)"
            nbButton
            status="primary"
            outline
            shape="round"
            [nbContextMenu]="contextMenuItems"
            [nbContextMenuTag]="cell.row.data">
            <nb-icon icon="settings-outline" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
          </button>
        </div>
      </ng-template>
    </igx-column>
  </igx-grid>
  <div class="margin-block-1 text-center">
    <button nbButton status="primary" shape="round" (click)="addOntology()">
      <nb-icon icon="plus"></nb-icon>New Ontology
    </button>
  </div>
</div>
<div *ngIf="error" class="width-fit margin-inline-auto margin-top-2">{{ error }} ❌</div>
