<div class="width-fit min-width-5 margin-inline-auto">
  <div *ngIf="mappingService.type === 'ontologyToOntology'">
    <div class="header-2 text-center">
      TMS will fetch all <b>terms</b> in the source ontology along with their <b>synonyms</b>,<br />then use them to
      find the best match in the target ontology.
    </div>
    <div class="height-4">
      <ng-select
        [(ngModel)]="sourceOntology"
        (change)="onSourceOntologyChange()"
        [placeholder]="'Select Source Ontology'"
        appearance="outline"
        [maxSelectedItems]="1"
        class="margin-inline-auto margin-top-1">
        <ng-option *ngFor="let ontology of ontologyService.getAvailableOntologies()" [value]="ontology.ontology_name">
          {{ ontology.ontology_name }}
        </ng-option>
      </ng-select>
    </div>
  </div>
</div>

<!-- Code below is hidden until we get permission to limit ontology mapping to a specified CSV list of IRI's -->
<div *ngIf="false" class="margin-inline-auto width-5 border-light-gray box-shadow">
  <nb-accordion>
    <nb-accordion-item [(collapsed)]="selectSourceSectionCollapsed">
      <nb-accordion-item-header>
        <span *ngIf="sourceOntology">✅</span>
        <span *ngIf="!sourceOntology">1.</span>
        &nbsp;&nbsp;Select Source Ontology
        <span *ngIf="sourceOntology">:&nbsp;</span><span class="text-selected">{{ sourceOntology }}</span>
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <div class="height-4">
          <ng-select
            [(ngModel)]="sourceOntology"
            (change)="onSourceOntologyChange()"
            [placeholder]="'Select Source Ontology'"
            appearance="outline"
            [maxSelectedItems]="1"
            class="margin-inline-auto margin-top-1">
            <ng-option
              *ngFor="let ontology of ontologyService.getAvailableOntologies()"
              [value]="ontology.ontology_name">
              {{ ontology.ontology_name }}
            </ng-option>
          </ng-select>
        </div>
      </nb-accordion-item-body>
    </nb-accordion-item>
    <nb-accordion-item [(collapsed)]="uploadCsvSectionCollapsed">
      <nb-accordion-item-header>
        <span *ngIf="mappingService.fileSettings.fileName">✅</span>
        <span *ngIf="!mappingService.fileSettings.fileName">2.</span>
        &nbsp;&nbsp;Upload .csv file
        <span *ngIf="mappingService.fileSettings.fileName">:&nbsp;</span>
        <span class="text-selected">{{ mappingService.fileSettings.fileName }}</span>
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <div class="header-2">
          Upload your .csv file containing the list of terms from <b>{{ sourceOntology }}</b> Ontology.
        </div>
        <div>• CSV file must include <b>IRI</b> column for each term to be mapped.</div>
        <nb-card
          [nbSpinner]="uploadingFile"
          nbSpinnerStatus="primary"
          class="width-fit margin-inline-auto margin-top-1">
          <nb-card-body>
            <div class="text-center">
              <div class="margin-bottom-1 header-2">Select <b>.csv</b> file to upload</div>
              <div class="text-small margin-bottom-1">Maximum File Size: {{ MAX_ALLOWED_SIZE_MB }}MB</div>
              <app-file-upload (fileSelected)="onFileSelected($event)" fileFormat=".csv"></app-file-upload>
              <div class="text-small text-error">{{ error }}</div>
            </div>
          </nb-card-body>
        </nb-card>
        <div class="text-small text-center">
          <div *ngIf="uploadingFile">Uploading File ...</div>
          <div *ngIf="mappingService.fileSettings.fileName">{{ mappingService.fileSettings.fileName }} uploaded.</div>
        </div>
      </nb-accordion-item-body>
    </nb-accordion-item>
    <nb-accordion-item [disabled]="csvColumns.length === 0" [(collapsed)]="selectIriColumnSectionCollapsed">
      <nb-accordion-item-header>
        <span *ngIf="iriColumn">✅</span>
        <span *ngIf="!iriColumn">3.</span>
        &nbsp;&nbsp;Select IRI Column
        <span *ngIf="iriColumn">:&nbsp;</span><span class="text-selected">{{ iriColumn }}</span>
      </nb-accordion-item-header>
      <nb-accordion-item-body>
        <div class="height-4">
          <ng-select
            [(ngModel)]="iriColumn"
            (change)="onIriColumnChange()"
            [placeholder]="'Select IRI Column'"
            appearance="outline"
            [maxSelectedItems]="1"
            class="margin-inline-auto margin-top-1">
            <ng-option *ngFor="let column of csvColumns" [value]="column">
              {{ column }}
            </ng-option>
          </ng-select>
        </div>
      </nb-accordion-item-body>
    </nb-accordion-item>
  </nb-accordion>
</div>
