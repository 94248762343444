import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ITmsRule } from 'src/app/interfaces/i-tms-rule';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-delete-tms-rule-dialog',
  templateUrl: './delete-tms-rule-dialog.component.html',
  styleUrls: ['./delete-tms-rule-dialog.component.scss'],
})
export class DeleteTmsRuleDialogComponent {
  @Input() tmsRule!: ITmsRule;
  loading = false;
  errorMsg: string | undefined;

  constructor(
    private dialogRef: NbDialogRef<unknown>,
    private api: ApiService
  ) {}

  close(updated = false) {
    this.dialogRef.close({ updated });
  }

  deleteRule() {
    this.loading = true;
    this.api.deleteMappingRule(this.tmsRule).subscribe({
      next: () => {
        this.loading = false;
        this.close(true);
      },
      error: err => {
        this.loading = false;
        if (err.status >= 500 || err.status === 0) {
          this.close();
        } else {
          this.errorMsg = err.statusText;
        }
      },
    });
  }
}
