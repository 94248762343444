<nb-card [nbSpinner]="loading" nbSpinnerStatus="primary" class="border-radius-1">
  <div class="white-fill padding-1 border-radius-1">
    <div class="header-1">
      Delete Ontology <b>{{ ontology.ontology_name }}</b>
    </div>
    <div class="margin-top-1">
      Are you sure you want to <b>delete</b> ontology <b>{{ ontology.ontology_name }}</b> ?
    </div>
    <div class="flex-y row-gap-1 margin-top-1">
      <div>Please Confirm Ontology Name below to delete this ontology:</div>
      <input
        class="margin-inline-auto"
        #ontologyName
        nbInput
        (input)="updateValues()"
        [placeholder]="ontology.ontology_name" />
      <div *ngIf="nameError" class="margin-top-1 text-error text-center">
        <b>{{ name }}</b> is not the name of this ontology<br />Please try again.
      </div>
      <div *ngIf="errorMsg" class="margin-top-1 text-error text-center">
        Error: <b>{{ errorMsg }}</b>
      </div>
      <div class="margin-top-2 flex-x column-gap-2 margin-inline-auto width-fit" [class.margin-top-2]="!nameError">
        <button nbButton outline status="primary" (click)="close()" shape="round">Cancel</button>
        <button nbButton status="danger" (click)="deleteOntology()" shape="round" [disabled]="name.length === 0">
          Delete
        </button>
      </div>
    </div>
  </div>
</nb-card>
