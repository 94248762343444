import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule,
  NbLayoutModule,
  NbButtonModule,
  NbStepperModule,
  NbToggleModule,
  NbRadioModule,
  NbCheckboxModule,
  NbInputModule,
  NbProgressBarModule,
  NbSpinnerModule,
  NbDialogModule,
  NbIconModule,
  NbCardModule,
  NbMenuModule,
  NbContextMenuModule,
  NbAutocompleteModule,
  NbFormFieldModule,
  NbTooltipModule,
  NbAccordionModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { KeycloakService } from './services/keycloak.service';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { DataSettingsComponent } from './pages/data-settings/data-settings.component';
import { MappingSettingsComponent } from './pages/mapping-settings/mapping-settings.component';
import { ChooseMappingTypeComponent } from './pages/choose-mapping-type/choose-mapping-type.component';
import { SubmitAndReviewComponent } from './pages/submit-and-review/submit-and-review.component';
import { IgxGridModule } from '@infragistics/igniteui-angular';
import { TaskListComponent } from './components/task-list/task-list.component';
import { NewMappingComponent } from './pages/new-mapping/new-mapping.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { UnknownErrorComponent } from './pages/unknown-error/unknown-error.component';
import { DownloadDialogComponent } from './components/download-dialog/download-dialog.component';
import { SelectColumnDialogComponent } from './components/select-column-dialog/select-column-dialog.component';
import { IndividualTermsMappingComponent } from './pages/individual-terms-mapping/individual-terms-mapping.component';
import { FileUploadMappingComponent } from './pages/file-upload-mapping/file-upload-mapping.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { MappingOptionsComponent } from './pages/mapping-options/mapping-options.component';
import { IndividualTermsGraphicComponent } from './components/individual-terms-graphic/individual-terms-graphic.component';
import { ColumnMappingGraphicComponent } from './components/column-mapping-graphic/column-mapping-graphic.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { FeedbackFormComponent } from './components/feedback-form/feedback-form.component';
import { ErrorForbiddenComponent } from './pages/error-forbidden/error-forbidden.component';
import { ManageOntologiesComponent } from './pages/manage-ontologies/manage-ontologies.component';
import { AddCustomOntologyDialogComponent } from './components/add-custom-ontology-dialog/add-custom-ontology-dialog.component';
import { DeleteOntologyDialogComponent } from './components/delete-ontology-dialog/delete-ontology-dialog.component';
import { ViewOntologyComponent } from './pages/view-ontology/view-ontology.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { UploadOntologyOwlFileDialogComponent } from './components/upload-ontology-owl-file-dialog/upload-ontology-owl-file-dialog.component';
import { OntologyMappingRulesComponent } from './pages/ontology-mapping-rules/ontology-mapping-rules.component';
import { DeleteTmsRuleDialogComponent } from './components/delete-tms-rule-dialog/delete-tms-rule-dialog.component';
import { AddMappingRuleDialogComponent } from './components/add-mapping-rule-dialog/add-mapping-rule-dialog.component';
import { InputWithSuggestionsComponent } from './components/input-with-suggestions/input-with-suggestions.component';
import { SearchResultsComponent } from './components/search-results/search-results.component';
import { MapOntologyGraphicComponent } from './components/map-ontology-graphic/map-ontology-graphic.component';
import { AncestryPathsComponent } from './components/ancestry-paths/ancestry-paths.component';
import { OntologyToOntologyDataInputComponent } from './pages/ontology-to-ontology-data-input/ontology-to-ontology-data-input.component';
import { TaskErrorsDialogComponent } from './components/task-errors-dialog/task-errors-dialog.component';

export function kcFactory(keycloakService: KeycloakService) {
  return () => keycloakService.init();
}

@NgModule({
  declarations: [
    AppComponent,
    PageHeaderComponent,
    DataSettingsComponent,
    MappingSettingsComponent,
    ChooseMappingTypeComponent,
    SubmitAndReviewComponent,
    TaskListComponent,
    NewMappingComponent,
    DashboardComponent,
    UnknownErrorComponent,
    DownloadDialogComponent,
    SelectColumnDialogComponent,
    IndividualTermsMappingComponent,
    FileUploadMappingComponent,
    FileUploadComponent,
    MappingOptionsComponent,
    IndividualTermsGraphicComponent,
    ColumnMappingGraphicComponent,
    FeedbackFormComponent,
    ErrorForbiddenComponent,
    ManageOntologiesComponent,
    AddCustomOntologyDialogComponent,
    DeleteOntologyDialogComponent,
    ViewOntologyComponent,
    UploadOntologyOwlFileDialogComponent,
    OntologyMappingRulesComponent,
    DeleteTmsRuleDialogComponent,
    AddMappingRuleDialogComponent,
    InputWithSuggestionsComponent,
    SearchResultsComponent,
    MapOntologyGraphicComponent,
    AncestryPathsComponent,
    OntologyToOntologyDataInputComponent,
    TaskErrorsDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'rancho' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbButtonModule,
    NbStepperModule,
    NbToggleModule,
    NbRadioModule,
    NbCheckboxModule,
    NbInputModule,
    NbProgressBarModule,
    IgxGridModule,
    NbSpinnerModule,
    NbDialogModule.forRoot({}),
    NbMenuModule.forRoot(),
    NbContextMenuModule,
    NbEvaIconsModule,
    NbIconModule,
    NbCardModule,
    NgSelectModule,
    FormsModule,
    NgxSliderModule,
    NbAutocompleteModule,
    NbFormFieldModule,
    NbTooltipModule,
    NbAccordionModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: kcFactory,
      deps: [KeycloakService, TokenInterceptor],
      multi: true,
    },
    TokenInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
