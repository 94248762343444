import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ITaskError } from 'src/app/interfaces/i-task-error';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-task-errors-dialog',
  templateUrl: './task-errors-dialog.component.html',
  styleUrls: ['./task-errors-dialog.component.scss'],
})
export class TaskErrorsDialogComponent implements OnInit {
  @Input() taskId!: string;
  taskErrors: ITaskError[] = [];
  loading = false;
  error = false;

  constructor(
    private dialogRef: NbDialogRef<unknown>,
    private api: ApiService
  ) {}

  ngOnInit() {
    this.getErrors();
  }

  close(updated = false) {
    this.dialogRef.close({ updated });
  }

  getErrors() {
    this.loading = true;
    this.api.getTaskErrors(this.taskId).subscribe({
      next: response => {
        this.loading = false;
        this.taskErrors = response.errors;
      },
      error: () => {
        this.error = true;
        this.loading = false;
      },
    });
  }
}
