import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MappingType } from 'src/app/enums/mapping-type';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-choose-mapping-type',
  templateUrl: './choose-mapping-type.component.html',
  styleUrls: ['./choose-mapping-type.component.scss'],
})
export class ChooseMappingTypeComponent implements OnInit {
  @Output() selectedType: EventEmitter<MappingType> = new EventEmitter();

  @ViewChild('taskNameInput') taskNameInput!: ElementRef<HTMLInputElement>;
  maxTermsCount = MappingService.MAX_TERMS_COUNT;

  constructor(
    public mappingService: MappingService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit() {
    this.mappingService.reset();
    this.analyticsService.trackEvent(
      'mapping_options_selector_loaded',
      'User is presented with the component to select a mapping type',
      'Test Category'
    );
  }

  mapIndividualTerms() {
    this.setMappingType(MappingType.INDIVIDUAL_TERMS);
  }

  uploadFile() {
    /**
     * TODO: Enter code to fetch file which contains terms here
     */
    this.setMappingType(MappingType.UPLOAD_FILE);
  }

  mapOntologyTermsToOntology() {
    this.setMappingType(MappingType.ONTOLOGY_TO_ONTOLOGY);
  }

  setMappingType(type: MappingType) {
    this.mappingService.type = type;
    this.selectedType.emit(type);
  }
}
