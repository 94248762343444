import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { IOntology } from 'src/app/interfaces/i-ontology';
import { OntologyService } from 'src/app/services/ontology.service';

@Component({
  selector: 'app-delete-ontology-dialog',
  templateUrl: './delete-ontology-dialog.component.html',
  styleUrls: ['./delete-ontology-dialog.component.scss'],
})
export class DeleteOntologyDialogComponent {
  @Input() ontology!: IOntology;
  @ViewChild('ontologyName') ontologyName: ElementRef | undefined;
  loading = false;
  name = '';
  public nameError = false;
  public errorMsg: string | undefined;

  constructor(
    private dialogRef: NbDialogRef<unknown>,
    private ontologyService: OntologyService
  ) {}

  close(updated = false) {
    this.dialogRef.close({ updated });
  }

  updateValues() {
    this.name = this.ontologyName?.nativeElement.value;
    this.nameError = false;
    this.errorMsg = undefined;
  }

  deleteOntology() {
    if (this.name !== this.ontology.ontology_name) {
      this.nameError = true;
    } else {
      this.loading = true;
      this.ontologyService.delete(this.ontology).subscribe({
        next: () => this.close(true),
        error: err => {
          this.loading = false;
          if (err.status >= 500 || err.status === 0) {
            this.close();
          } else {
            this.errorMsg = err.statusText;
          }
        },
        complete: () => (this.loading = false),
      });
    }
  }
}
