<div class="grid-2-columns row-gap-2 column-gap-3 width-fit margin-inline-auto padding-block-auto margin-top-1">
  <div>Job Name</div>
  <div>
    <input type="text" [(ngModel)]="options.jobTitle" nbInput (input)="saveNewSettings()" />
  </div>
  <div class="margin-block-auto">
    Mapping Type
    <div class="min-height-1-5"></div>
  </div>
  <div class="margin-block-auto">
    <nb-radio-group [(value)]="options.mappingMethodologyValue" class="flex-x" (valueChange)="saveNewSettings()">
      <nb-radio *ngFor="let method of mappingService.mappingMethodologies" [value]="method.value">{{
        method.label | uppercase
      }}</nb-radio>
    </nb-radio-group>
    <div class="min-height-1-5 width-4">
      <div *ngIf="options.mappingMethodologyValue === 'ai' && aiNotAvailableOntologies.length > 0" class="text-error">
        <b>AI</b> is not available for <b>{{ aiNotAvailableOntologies.join(', ') }}</b>
      </div>
    </div>
  </div>
  <ng-container *ngIf="mappingService.type === 'individual'">
    <div class="margin-block-auto">Maximum # of suggestions per Ontology</div>
    <div class="margin-block-auto">
      <ng-select
        [(ngModel)]="options.maxResultsPerOntology"
        (change)="saveNewSettings()"
        [clearable]="false"
        [closeOnSelect]="true"
        appearance="outline"
        class="small">
        <ng-option *ngFor="let i of suggestionsPerOntology" [value]="i + 1">{{ i + 1 }}</ng-option>
      </ng-select>
    </div>
  </ng-container>
  <div class="margin-block-auto">Minimum Similarity Score</div>
  <div>
    <ngx-slider
      aria-disabled="true"
      [(value)]="simScoreThreshold!"
      [options]="sliderOptions"
      (valueChange)="updateSimScoreThreshold()"></ngx-slider>
  </div>
  <ng-container *ngIf="['individual', 'filecolumns'].includes(mappingService.type)">
    <div>Case Sensitive Mapping</div>
    <div>
      <nb-toggle status="primary" [(checked)]="options.caseSensitive" (checkedChange)="saveNewSettings()"></nb-toggle>
    </div>
  </ng-container>
</div>
