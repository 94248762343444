<div class="grid-3-columns row-gap-1 column-gap-1">
  <div class="text-center">Ontology Terms</div>
  <div></div>
  <div class="text-center">Mapped Terms</div>
  <div class="border-radius-2 text-center height-2 width-2 dark-green-fill" style="opacity: 0.7"></div>
  <div class="text-center"><nb-icon icon="arrow-forward"></nb-icon></div>
  <div class="flex-y row-gap-1">
    <ng-container *ngFor="let index of [0, 1, 2, 3, 4, 5, 6]">
      <div class="border-radius-1 height-1 width-3 primary-fill text-center"></div>
    </ng-container>
  </div>
</div>
