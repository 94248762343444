import { Injectable } from '@angular/core';
import { IMappingPayload } from '../interfaces/i-terminology-mapping-payload';
import { MappingType } from '../enums/mapping-type';
import { IMappingOptions } from '../interfaces/i-mapping-options';
import { IDataSettings } from '../interfaces/i-data-settings';
import { firstValueFrom } from 'rxjs';
import { IColumnTerms, IParsedFile } from '../interfaces/i-parsed-file';
import { ApiService } from './api.service';
import { MappingService } from './mapping.service';
import { OntologyService } from './ontology.service';
import { IOntologyToOntologyMappingPayload } from '../interfaces/i-ontology-to-ontology-mapping-payload';

@Injectable({
  providedIn: 'root',
})
export class ReRunMappingService {
  public allUnavailableOntologies: string[] = [];
  public sourceOntologyUnavailable: string | undefined;
  constructor(
    private api: ApiService,
    private mappingService: MappingService,
    private ontologyService: OntologyService
  ) {}

  async loadSettings(taskRequestUrl: string, payload: IMappingPayload | IOntologyToOntologyMappingPayload) {
    this.allUnavailableOntologies = [];
    this.sourceOntologyUnavailable = undefined;
    if ((payload as IMappingPayload).terms_input_type) {
      payload = payload as IMappingPayload;
      this.loadCommonSettings(payload);
      if (payload.terms_input_type === MappingType.INDIVIDUAL_TERMS) {
        this.loadIndividualTermsSettings(payload);
      } else if (payload.terms_input_type === MappingType.UPLOAD_FILE) {
        await this.loadFileColumnTermsSettings(taskRequestUrl, payload);
      } else {
        console.error('Unknown file type. Cannot load Task');
      }
    } else if ((payload as IOntologyToOntologyMappingPayload).source_ontology) {
      this.loadOntoToOntoSettings(payload as IOntologyToOntologyMappingPayload);
    } else {
      console.error('Unknown Request Payload Object Type receiced. Cannot load task');
    }
  }

  private loadCommonSettings(payload: IMappingPayload) {
    this.mappingService.type = payload.terms_input_type;
    const newOptions: IMappingOptions = {
      mappingMethodologyValue: payload.task_name,
      maxResultsPerOntology: payload.params.limit
        ? payload.params.limit
        : this.mappingService.DEFAULT_MAX_RESULTS_PER_ONTOLOGY,
      jobTitle: payload.label ? payload.label : '',
      similarityScoreThreshold: payload.params.similarity_score_threshold
        ? payload.params.similarity_score_threshold
        : this.mappingService.DEFAULT_SIMILARITY_SCORE_THRESHOLD,
      caseSensitive: payload.params.case_sensitive,
    };
    this.mappingService.mappingOptions = newOptions;
  }

  private loadIndividualTermsSettings(payload: IMappingPayload) {
    const termsList: string[] = [];
    const ontologyList: string[][] = [];
    payload.params.terms?.forEach(term => {
      termsList.push(term.term);
      const verifiedOntologyList = this.verifyOntologyList(term.ontologies);
      ontologyList.push(verifiedOntologyList);
    });
    const newSettings: IDataSettings = {
      terms: termsList,
    };
    this.mappingService.dataSettings = newSettings;
    this.mappingService.settings.forEach((value, index) => {
      value.ontologies = ontologyList[index];
    });
  }

  private async loadFileColumnTermsSettings(taskRequestUrl: string, payload: IMappingPayload) {
    const metadata = await firstValueFrom(this.api.getTaskRequestInputFileMetadata(taskRequestUrl));
    if (metadata) {
      const columns: IColumnTerms[] = [];
      let totalTerms = 0;
      metadata.file_columns.forEach(column => {
        const selectedColumn = payload.params?.term_columns?.find(sCol => sCol.column_header === column.column_name);
        const colTermsCount = column.num_unique_values;
        totalTerms += colTermsCount;
        columns.push({
          columnHeader: column.column_name,
          terms: new Array<string>(colTermsCount),
          ontologies: selectedColumn ? this.verifyOntologyList(selectedColumn.ontologies) : [],
        });
      });
      const file: IParsedFile = {
        data: columns,
        totalTerms,
      };
      this.mappingService.fileSettings = {
        fileData: file,
        fileName: metadata.file_name,
        fileUrl: metadata.file_url_path,
      };
    }
  }

  private async loadOntoToOntoSettings(payload: IOntologyToOntologyMappingPayload) {
    this.mappingService.type = MappingType.ONTOLOGY_TO_ONTOLOGY;
    const sourceOntology = this.verifyOntologyList([payload.source_ontology]);
    if (sourceOntology.length > 0) {
      this.mappingService.ontologyToOntologySettings = {
        source: sourceOntology[0],
        target:
          this.verifyOntologyList([payload.target_ontology]).length > 0
            ? this.verifyOntologyList([payload.target_ontology])[0]
            : undefined,
        iriColumn: payload.input_file_iri_column,
        fileUrl: payload.input_file_url_path,
      };
    } else {
      this.sourceOntologyUnavailable = payload.source_ontology;
      this.allUnavailableOntologies = [];
    }
    this.mappingService.mappingOptions = {
      mappingMethodologyValue: payload.tms_mapping_function,
      similarityScoreThreshold: payload.similarity_score_threshold,
      jobTitle: payload.label,
    };
    this.verifyOntologyList([payload.target_ontology]);
  }

  private verifyOntologyList(incomingOntologies: string[]): string[] {
    const availableIncomingOnotologies: string[] = incomingOntologies.filter(o =>
      this.ontologyService.availableOntologyNames.includes(o)
    );
    const unavailableOnotologies: string[] = incomingOntologies.filter(
      o => !this.ontologyService.availableOntologyNames.includes(o)
    );
    unavailableOnotologies.forEach(o => {
      if (this.allUnavailableOntologies.indexOf(o) === -1) {
        this.allUnavailableOntologies.push(o);
      }
    });
    return availableIncomingOnotologies;
  }
}
