import { Component, Input, OnDestroy } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { IOntology } from 'src/app/interfaces/i-ontology';
import { IOntologyFileUploadResponse } from 'src/app/interfaces/i-ontology-file-upload-response';
import { OntologyService } from 'src/app/services/ontology.service';

@Component({
  selector: 'app-upload-ontology-owl-file-dialog',
  templateUrl: './upload-ontology-owl-file-dialog.component.html',
  styleUrls: ['./upload-ontology-owl-file-dialog.component.scss'],
})
export class UploadOntologyOwlFileDialogComponent implements OnDestroy {
  @Input() ontology!: IOntology;

  loading = false;
  error = false;
  errorMsgs: string[] = [];
  fileUploaded = false;
  fuzzyMapReady = false;
  aiMapReady = false;
  estimatedRunTime: number | undefined;
  file: File | undefined;

  private statusPoller: ReturnType<typeof setInterval> | undefined;

  constructor(
    private dialogRef: NbDialogRef<unknown>,
    private ontologyService: OntologyService
  ) {}

  ngOnDestroy() {
    clearInterval(this.statusPoller);
  }

  close(updated = true) {
    this.dialogRef.close({ updated });
  }

  async onFileSelected(file: File) {
    this.file = file;
  }

  uploadNewOwlFile() {
    if (this.file === undefined) {
      console.error('Tried uploading a new Ontology file without selecting a file first. Investigate this bug');
    } else {
      this.resetLoader();
      this.loading = true;
      this.ontologyService.uploadOwlFile(this.ontology, this.file).subscribe({
        next: value => {
          this.fileUploaded = true;
          this.estimatedRunTime = value.estimated_run_time;
          this.pollStatus(value.id);
        },
        error: err => {
          if (err.status >= 500 || err.status === 0) {
            this.close();
          } else {
            this.errorMsgs.push('Error: ' + err.status + ' - ' + err.statusText);
            this.raiseError();
          }
        },
      });
    }
  }

  private raiseError(response?: IOntologyFileUploadResponse) {
    this.error = true;
    this.loading = false;
    const errorsList: (string | undefined)[] | undefined = response?.status_by_datatype?.map(status =>
      status.message ? status.load_data_type.toUpperCase() + ': ' + status.message : ''
    );
    if (errorsList !== undefined) {
      errorsList.forEach(errMsg => {
        if (errMsg !== undefined) {
          this.errorMsgs?.push(errMsg);
        }
      });
    }
  }

  private pollStatus(taskId: string) {
    let status$: Subscription = new Subscription();
    this.statusPoller = setInterval(() => {
      status$ = this.ontologyService.getUploadOwlFileStatus(taskId).subscribe({
        next: response => {
          status$.unsubscribe();
          if (response !== undefined) {
            this.fileUploaded = true;
            this.estimatedRunTime = response.estimated_run_time;
            if (response.status === 'COMPLETED') {
              this.loading = false;
              clearInterval(this.statusPoller);
              this.close();
            } else if (response.status === 'FAILED') {
              this.loading = false;
              clearInterval(this.statusPoller);
              this.raiseError(response);
            } else {
              const dataTypes = response.status_by_datatype;
              dataTypes.forEach(type => {
                switch (type.load_data_type) {
                  case 'fuzzy': {
                    if (type.status === 'COMPLETED') {
                      this.fuzzyMapReady = true;
                    }
                    break;
                  }
                  case 'ai': {
                    if (type.status === 'COMPLETED') {
                      this.aiMapReady = true;
                    }
                    break;
                  }
                }
              });
            }
          }
        },
        error: err => {
          clearInterval(this.statusPoller);
          if (err.status >= 500 || err.status === 0) {
            this.close();
          } else {
            this.errorMsgs.push('Error: ' + err.status + ' - ' + err.statusText);
            this.raiseError();
          }
        },
      });
    }, 5000);
  }

  private resetLoader() {
    this.error = false;
    this.fileUploaded = false;
    this.fuzzyMapReady = false;
    this.aiMapReady = false;
    this.estimatedRunTime = undefined;
    this.errorMsgs = [];
  }
}
