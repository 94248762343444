<div>
  <igx-grid
    [data]="rules ? rules : []"
    [autoGenerate]="false"
    [height]="null"
    [emptyGridMessage]="loading ? ' ' : error ? '❌ ERROR: ' + error : 'No Rules Added to this ontology'"
    [isLoading]="loading"
    [allowFiltering]="true">
    <igx-grid-toolbar>
      <igx-grid-toolbar-title>
        <b>Mapping Rules for {{ ontology ? ontology.ontology_name : '...' }}</b>
      </igx-grid-toolbar-title>
    </igx-grid-toolbar>
    <igx-paginator></igx-paginator>
    <igx-column field="query_term" header="Query Term" [resizable]="true" [sortable]="true"></igx-column>
    <igx-column field="mapped_term.label" header="Maps to" [resizable]="true" [sortable]="true"> </igx-column>
    <igx-column field="mapped_term.curie" header="CURIE" [resizable]="true" [sortable]="true"> </igx-column>
    <igx-column field="mapped_term.iri" header="IRI" [resizable]="true" [sortable]="true">
      <ng-template igxCell let-val>
        <a [href]="val" target="_blank">{{ val }}</a>
      </ng-template>
    </igx-column>
    <igx-column field="mapped_term.synonyms" header="Synonyms" [resizable]="true">
      <ng-template igxCell let-val>
        {{ val.join(', ') }}
      </ng-template>
    </igx-column>
    <igx-column
      field="updated_at"
      header="Last Updated"
      [resizable]="true"
      dataType="date"
      [sortable]="true"></igx-column>
    <igx-column header="Actions" [width]="'fit-content'" [dataType]="'number'" [filterable]="false">
      <ng-template igxCell let-cell="cell">
        <div class="flex-x column-gap-1">
          <button nbButton status="danger" shape="round" (click)="deleteRule(cell.row.data)">
            <nb-icon icon="trash-2-outline" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
          </button>
        </div>
      </ng-template>
    </igx-column>
    <igx-column [hidden]="true" field="tms_rule_id" header="ID" [resizable]="true"></igx-column>
  </igx-grid>

  <div class="margin-inline-auto margin-top-1 width-fit">
    <button nbButton shape="round" status="primary" (click)="addRule()" [disabled]="ontology === undefined">
      + New Rule
    </button>
  </div>
</div>
