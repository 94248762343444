import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ISortingExpression } from '@infragistics/igniteui-angular';
import { NbDialogService } from '@nebular/theme';
import { Subscription } from 'rxjs';
import { AddMappingRuleDialogComponent } from 'src/app/components/add-mapping-rule-dialog/add-mapping-rule-dialog.component';
import { DeleteTmsRuleDialogComponent } from 'src/app/components/delete-tms-rule-dialog/delete-tms-rule-dialog.component';
import { DownloadDialogComponent } from 'src/app/components/download-dialog/download-dialog.component';
import { TaskErrorsDialogComponent } from 'src/app/components/task-errors-dialog/task-errors-dialog.component';
import { MappingType } from 'src/app/enums/mapping-type';
import { IMappingResult } from 'src/app/interfaces/i-mapping-result';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { MappingService } from 'src/app/services/mapping.service';

@Component({
  selector: 'app-submit-and-review',
  templateUrl: './submit-and-review.component.html',
  styleUrls: ['./submit-and-review.component.scss'],
})
export class SubmitAndReviewComponent implements OnInit, OnDestroy {
  @Output() mappingComplete: EventEmitter<boolean> = new EventEmitter();
  @Output() mappingCancelled: EventEmitter<boolean> = new EventEmitter();

  runId: string | undefined;
  percentageComplete = 0;
  ready = false;
  results: IMappingResult | undefined;
  gridData: unknown[] | undefined;
  loadingResults = false;
  startingMapping: boolean | undefined;
  cancelling = false;
  mappingError: string | undefined;
  queriesFailed: number | undefined;
  readonly userMapLabel: string = 'USER-MAP';
  private subsriptions: Subscription[] = [];
  private statusPoller: ReturnType<typeof setInterval> | undefined;

  constructor(
    private mappingService: MappingService,
    private dialogService: NbDialogService,
    private analytics: AnalyticsService
  ) {}

  ngOnInit() {
    this.startingMapping = true;
    this.mappingComplete.next(false);
    this.mappingCancelled.emit(false);
    this.mappingService.mapToOntologies().subscribe({
      next: result => {
        this.runId = result.id;
        let status$: Subscription = new Subscription();
        this.statusPoller = setInterval(() => {
          if (this.runId) {
            this.ready = false;
            status$ = this.mappingService.getMapping(this.runId).subscribe(response => {
              status$.unsubscribe();
              if (response !== undefined) {
                if (response.progress) {
                  this.percentageComplete = Math.round(response.progress * 10) / 10;
                }
                if (response.ready || response.status.toLowerCase() === 'completed') {
                  this.ready = true;
                  this.queriesFailed = response.queries_failed;
                  clearInterval(this.statusPoller);
                  this.mappingComplete.next(true);
                  this.analytics.trackEvent(
                    'new_mapping_result_completed_success',
                    'New Mapping has successfully completed',
                    'new-mapping-result'
                  );
                  if (this.mappingService.type === MappingType.INDIVIDUAL_TERMS) {
                    this.previewResults();
                  }
                } else if (response.status === 'FAILED') {
                  clearInterval(this.statusPoller);
                  this.showError();
                }
              }
            });
          }
        }, 1000);
      },
      error: err => {
        this.showError(err);
      },
      complete: () => (this.startingMapping = false),
    });
  }

  ngOnDestroy() {
    clearInterval(this.statusPoller);
    this.subsriptions.forEach(sub => sub.unsubscribe());
  }

  cancelTask() {
    if (this.runId) {
      this.cancelling = true;
      this.mappingService.cancelTask(this.runId).subscribe({
        next: () => this.mappingCancelled.emit(true),
        error: err => this.showError(err),
        complete: () => (this.cancelling = false),
      });
    } else {
      this.mappingCancelled.emit(true);
    }
  }

  previewResults() {
    if (this.runId) {
      this.loadingResults = true;
      this.gridData = [];
      const sub = this.mappingService.getResult(this.runId).subscribe({
        next: results => {
          this.results = results;
          this.gridData = this.mappingService.formatResultsToIgxGrid(results);
        },
        error: err => {
          this.showError(err);
        },
        complete: () => {
          this.loadingResults = false;
        },
      });
      this.subsriptions.push(sub);
    }
  }

  downloadResults() {
    this.analytics.trackEvent(
      'new_mapping_result_download_clicked',
      'User Clicked download on new mapping results',
      'new-mapping-result'
    );
    if (this.runId) {
      this.dialogService.open(DownloadDialogComponent, {
        context: {
          taskId: this.runId,
          inputType: this.mappingService.type,
          mappingMethodology: this.mappingService.getMappingMethodology(
            this.mappingService.mappingOptions.mappingMethodologyValue
          ),
          totalTerms: this.mappingService.dataSettings.terms.length,
          fileName: this.mappingService.mappingOptions.jobTitle,
        },
      });
    }
  }

  onSortingDone(sort: ISortingExpression | ISortingExpression[]) {
    const columnName = (sort as ISortingExpression).fieldName.replace(/\s/g, '');
    this.analytics.trackEvent(
      'new_mapping_result_sort_' + columnName,
      'User clicked on Sort ' + columnName + ' in Mapping Results Table',
      'new-mapping-result'
    );
  }

  private showError(err?: HttpErrorResponse) {
    this.mappingComplete.next(true);
    if (err) {
      this.mappingError = err.status + ' - ' + err.statusText;
    } else {
      this.mappingError = 'Job FAILED';
    }
    this.analytics.trackEvent(
      'new_mapping_result_failed',
      'New Mapping has failed and not completed',
      'new-mapping-result'
    );
  }

  addMappingRule(data: {
    'query term': string;
    'ontology name': string;
    term_id: string;
    label: string;
    'similarity score': string;
    mapping_rule_id: string | undefined;
  }) {
    const query_term = data['query term'];
    const ontology_name = data['ontology name'];
    const term_id = data['term_id'];
    const label = data['label'];
    this.dialogService
      .open(AddMappingRuleDialogComponent, {
        context: {
          newRule: {
            term_id,
            query_term,
            ontology_name,
            label,
          },
        },
      })
      .onClose.subscribe(result => {
        if (result?.ruleAdded) {
          data['similarity score'] = this.userMapLabel;
          data['mapping_rule_id'] = result.ruleAdded.tms_rule_id;
          this.analytics.trackEvent(
            'mapping_rule_added_from_results_page',
            'User added a new mapping rule from the mapping results page',
            'mapping_rule'
          );
        }
      });
  }

  deleteMappingRule(data: {
    'query term': string;
    'ontology name': string;
    term_id: string;
    label: string;
    iri: string;
    'similarity score': string;
    mapping_rule_id: string;
  }) {
    const query_term = data['query term'];
    const ontology_name = data['ontology name'];
    const term_id = data['term_id'];
    const tms_rule_id = data['mapping_rule_id'];
    const label = data['label'];
    const iri = data['iri'];
    this.dialogService
      .open(DeleteTmsRuleDialogComponent, {
        context: {
          tmsRule: {
            tms_rule_id,
            query_term,
            ontology_name,
            mapped_term: {
              term_id,
              label,
              iri,
            },
          },
        },
      })
      .onClose.subscribe(result => {
        if (result.updated) {
          data['similarity score'] = '';
          this.analytics.trackEvent(
            'mapping_rule_deleted_from_results_page',
            'User Deleted a mapping rule from the mapping results page',
            'mapping_rule'
          );
        }
      });
  }

  canAddMappingRule(data: {
    'query term': string;
    'ontology name': string;
    'similarity score': string | number | undefined;
  }): boolean {
    if (data['similarity score'] && !data['similarity score'].toString().includes(this.userMapLabel)) {
      const existingMapping = this.gridData?.filter(
        (row: any) =>
          row['query term'] === data['query term'] &&
          row['ontology name'] === data['ontology name'] &&
          row['similarity score'].toString().includes(this.userMapLabel)
      );
      if (existingMapping?.length === 0) {
        return true;
      }
    }
    return false;
  }

  viewTaskErrors() {
    if (this.runId) {
      this.dialogService.open(TaskErrorsDialogComponent, {
        context: {
          taskId: this.runId,
        },
      });
    }
  }
}
