import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IMappingPayload } from '../interfaces/i-terminology-mapping-payload';
import { ITerminologyMapping } from '../interfaces/i-mapping-status';
import { IMappingResult } from '../interfaces/i-mapping-result';
import { IAllMappings } from '../interfaces/i-all-mappings';
import { IFileUploadResponse } from '../interfaces/i-file-upload-response';
import { ITermsInputFileResponse } from '../interfaces/i-terms-input-file-response';
import { IOntology } from '../interfaces/i-ontology';
import { IOntologyFileUploadResponse, IOntologyIngestionTasks } from '../interfaces/i-ontology-file-upload-response';
import { ITmsRule, ITmsRuleAdd } from '../interfaces/i-tms-rule';
import { IOntologyTerm } from '../interfaces/i-ontology-term';
import { ISearchResult } from '../interfaces/i-search-result';
import { IGetOntologiesResult } from '../interfaces/i-get-ontologies-result';
import { IOntologyToOntologyMappingPayload } from '../interfaces/i-ontology-to-ontology-mapping-payload';
import { IOntoToOntoMapping, IOntoToOntoMappingList } from '../interfaces/i-onto-to-onto-mapping';
import { MappingType } from '../enums/mapping-type';
import { ITaskError } from '../interfaces/i-task-error';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private readonly BASE_URL: string = environment.baseUrl;
  constructor(private http: HttpClient) {}

  fetchAllMappings(): Observable<IAllMappings> {
    return forkJoin({
      termMappings: this.http.get<IAllMappings>(this.BASE_URL + 'terminology-mappings'),
      ontoToOntoMappings: this.fetchAllOntologyToOntologyMappings(),
    }).pipe(
      map(responses => {
        const allMappings = responses.termMappings.tasks.concat(
          responses.ontoToOntoMappings.tasks.map(ontoToOnto => {
            const termMapping: ITerminologyMapping = {
              id: ontoToOnto.id,
              label: ontoToOnto.task_request.label,
              terms_input_type: MappingType.ONTOLOGY_TO_ONTOLOGY,
              task_name: ontoToOnto.task_request.tms_mapping_function,
              ontologies: [ontoToOnto.task_request.source_ontology, ontoToOnto.task_request.target_ontology],
              submitted_at: ontoToOnto.submitted_at,
              submitted_by: ontoToOnto.submitted_by,
              completed_at: ontoToOnto.completed_at,
              terms_completed: ontoToOnto.total_mapped_source_iris,
              total_terms: ontoToOnto.total_source_iris,
              status: ontoToOnto.status,
              message: ontoToOnto.message,
              terms_mapped_input_file_url: ontoToOnto.terms_mapped_input_file_url,
              task_request_url: ontoToOnto.task_request_url,
              progress: ontoToOnto.progress,
              mapping_type: MappingType.ONTOLOGY_TO_ONTOLOGY,
            };
            return termMapping;
          })
        );
        allMappings.sort((a, b) => b.submitted_at.valueOf() - a.submitted_at.valueOf());
        return {
          current_limit: responses.termMappings.current_limit,
          current_offset: responses.termMappings.current_offset,
          tasks: allMappings,
          total_tasks: responses.termMappings.total_tasks + responses.ontoToOntoMappings.total_tasks,
        };
      })
    );
  }

  getOntologies(offset = 0, limit = 1000): Observable<IGetOntologiesResult> {
    return this.http.get<IGetOntologiesResult>(this.BASE_URL + 'ontologies', { params: { offset, limit } }).pipe(
      map(response => {
        response.result.forEach(ontology => {
          if (!ontology.ontology_version) {
            ontology.ontology_version = '';
          }
        });
        return response;
      })
    );
  }

  postOntology(ontology_name: string, ontology_version: string, visibility = 'private'): Observable<IOntology> {
    return this.http.post<IOntology>(this.BASE_URL + 'ontologies', {
      ontology_name,
      ontology_version,
      visibility,
    });
  }

  putOntology(
    ontology_id: string,
    ontology_name: string,
    ontology_version: string,
    visibility: string
  ): Observable<IOntology> {
    return this.http.put<IOntology>(this.BASE_URL + 'ontologies', {
      ontology_id,
      ontology_name,
      ontology_version,
      visibility,
    });
  }

  deleteOntology(id: string): Observable<unknown> {
    return this.http.delete(this.BASE_URL + 'ontologies/' + id);
  }

  uploadOwlFile(ontology_id: string, file_url_path: string): Observable<IOntologyFileUploadResponse> {
    return this.http.post<IOntologyFileUploadResponse>(this.BASE_URL + 'ontology-ingestion', {
      ontology_id,
      file_url_path,
    });
  }

  getUploadOwlFileStatus(id: string): Observable<IOntologyFileUploadResponse | undefined> {
    return this.http.get<IOntologyIngestionTasks>(this.BASE_URL + 'ontology-ingestion?id=' + id).pipe(
      map(response => {
        if (response.tasks.length === 1) {
          return response.tasks[0];
        } else {
          return undefined;
        }
      })
    );
  }

  getChildren(ontologyId: string, parentIri: string, offset: number, limit: number): Observable<ISearchResult> {
    return this.http.get<ISearchResult>(this.BASE_URL + 'ontology-data/' + ontologyId + '/children', {
      params: {
        parent_iri: parentIri,
        offset,
        limit,
      },
    });
  }

  getAncestors(ontologyId: string, term_iri: string): Observable<IOntologyTerm[][]> {
    return this.http.get<IOntologyTerm[][]>(this.BASE_URL + 'ontology-data/' + ontologyId + '/ancestors', {
      params: {
        term_iri,
      },
    });
  }

  searchTerm(
    ontologyId: string,
    query: string,
    parents_only = false,
    offset = 0,
    limit = 1000
  ): Observable<ISearchResult> {
    return this.http.get<ISearchResult>(this.BASE_URL + `ontology-data/${ontologyId}/search`, {
      params: { query, parents_only, limit, offset },
    });
  }

  fetchMappingMethodologies(): Observable<string[]> {
    return this.http.get<string[]>(this.BASE_URL + 'task/names');
  }

  fetchResultHeaders(methodology: string): Observable<string[]> {
    return this.http.get<string[]>(this.BASE_URL + 'task/result-headers', {
      params: {
        task_name: methodology,
      },
    });
  }

  uploadFile(file: File): Observable<IFileUploadResponse> {
    const formData = new FormData();
    formData.append('upload_file', file);
    return this.http.post<IFileUploadResponse>(this.BASE_URL + 'files', formData);
  }

  submit(newTask: IMappingPayload): Observable<ITerminologyMapping> {
    return this.http.post<ITerminologyMapping>(this.BASE_URL + 'terminology-mappings', newTask);
  }

  updateTask(updatedTask: ITerminologyMapping): Observable<ITerminologyMapping> {
    return this.http.put<ITerminologyMapping>(this.BASE_URL + 'terminology-mappings/' + updatedTask.id, updatedTask);
  }

  getMapping(taskId: string): Observable<ITerminologyMapping> {
    return this.http.get<ITerminologyMapping>(this.BASE_URL + 'terminology-mappings/' + taskId);
  }

  getResult(taskId: string, limit = 1000): Observable<IMappingResult> {
    return this.http.get<IMappingResult>(this.BASE_URL + 'terminology-mappings/' + taskId + '/result', {
      params: { limit },
    });
  }

  getTaskRequest(requestUrl: string): Observable<IMappingPayload | IOntologyToOntologyMappingPayload> {
    requestUrl = requestUrl.substring(1); // Remove leading '/'
    return this.http.get<IMappingPayload | IOntologyToOntologyMappingPayload>(this.BASE_URL + requestUrl).pipe(
      map(payload => {
        if ((payload as IMappingPayload).params) {
          const mappingPayload = payload as IMappingPayload;
          const termsList: { term: string; ontologies: string[] }[] = [];
          mappingPayload.params.terms?.forEach(term => {
            if (typeof term === 'string') {
              const normalizedTerm = {
                term,
                ontologies: mappingPayload.params.ontologies ? mappingPayload.params.ontologies : [],
              };
              termsList.push(normalizedTerm);
            } else {
              termsList.push(term);
            }
          });
          mappingPayload.params.terms = termsList;
          return mappingPayload;
        } else {
          return payload;
        }
      })
    );
  }

  getTaskRequestInputFileMetadata(requestUrl: string): Observable<ITermsInputFileResponse> {
    return this.http.get<ITermsInputFileResponse>(this.BASE_URL + requestUrl + '/terms-input-file');
  }

  cancelTask(taskId: string): Observable<ITerminologyMapping> {
    return this.http.post<ITerminologyMapping>(this.BASE_URL + 'terminology-mappings/' + taskId + '/cancel', {});
  }

  async download(taskId: string, fileName: string, headers: string[] = [], result_type = 'csv') {
    switch (result_type) {
      case 'json': {
        await new Promise<void>((resolved, failed) => {
          const sub$ = this.getResult(taskId, 999999999999).subscribe({
            next: result => {
              const jsonData = {
                totalResults: result.total_results,
                data: this.formatMappingResultsToJson(result, headers),
              };
              this.createFile(JSON.stringify(jsonData), fileName, result_type);
              sub$.unsubscribe();
              resolved();
            },
            error: err => failed(err),
          });
        });
        break;
      }
      default: {
        await this.downloadFile(fileName, this.BASE_URL + 'terminology-mappings/' + taskId + '/download', result_type, {
          headers,
          result_type,
        });
      }
    }
  }

  async downloadTermsMappedFile(taskId: string, fileName: string, headers: string[] = []) {
    await this.downloadFile(
      fileName,
      this.BASE_URL + 'terminology-mappings/' + taskId + '/terms-mapped-input/csv',
      'csv',
      {
        headers,
      }
    );
  }

  formatMappingResultsToJson(results: IMappingResult, headers?: string[]): unknown[] {
    const returnArray: unknown[] = [];
    results.data.forEach(value => {
      const gridRow: {
        [key: string]: string;
      } = {};
      results.header.forEach((column, index) => {
        if (['synonyms', 'info'].includes(column.toLowerCase())) {
          value[index] = value[index]?.split('|').join(', ');
        }
        if (headers === undefined || headers.includes(column)) {
          gridRow[column] = value[index];
        }
      });
      returnArray.push(gridRow);
    });
    return returnArray;
  }

  getTaskErrors(
    taskId: string
  ): Observable<{ current_limit: number; current_offset: number; total_errors: number; errors: ITaskError[] }> {
    return this.http.get<{ current_limit: number; current_offset: number; total_errors: number; errors: ITaskError[] }>(
      this.BASE_URL + 'terminology-mappings/' + taskId + '/errors'
    );
  }

  getMappingRules(ontology_id: string): Observable<ITmsRule[]> {
    return this.http.get<ITmsRule[]>(this.BASE_URL + 'mapping-rules', { params: { ontology_id } });
  }

  addMappingRule(newRule: ITmsRuleAdd): Observable<ITmsRule> {
    return this.http.post<ITmsRule>(this.BASE_URL + 'mapping-rules', newRule);
  }

  deleteMappingRule(tmsRule: ITmsRule): Observable<ITmsRule> {
    return this.http.delete<ITmsRule>(this.BASE_URL + 'mapping-rules/' + tmsRule.tms_rule_id);
  }

  private downloadFile(
    fileName: string,
    url: string,
    fileType: string,
    params?:
      | HttpParams
      | {
          [param: string]: string | number | boolean | readonly (string | number | boolean)[];
        }
      | undefined
  ): Promise<void> {
    return new Promise((resolved, failed) => {
      const sub$ = this.http
        .get(url, {
          responseType: 'blob',
          params,
        })
        .subscribe({
          next: async data => {
            await this.createFile(data, fileName, fileType);
            sub$.unsubscribe();
            resolved();
          },
          error: err => failed(err),
        });
    });
  }

  private async createFile(data: BlobPart, fileName: string, fileType: string) {
    const blob = new Blob([data], { type: 'application/' + fileType });
    const downloadURL = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadURL;
    link.download = fileName + '.' + fileType;
    link.click();
  }

  fetchAllOntologyToOntologyMappings(): Observable<IOntoToOntoMappingList> {
    return this.http.get<IOntoToOntoMappingList>(this.BASE_URL + 'ontology-to-ontology');
  }

  getOntologyToOntology(id: string): Observable<IOntoToOntoMapping | undefined> {
    return this.http
      .get<IOntoToOntoMappingList>(this.BASE_URL + 'ontology-to-ontology', {
        params: {
          id,
        },
      })
      .pipe(
        map(response => {
          if (response.total_tasks > 0) {
            return response.tasks[0];
          } else {
            throwError(() => {
              new HttpErrorResponse({ status: 404, statusText: 'Ontology-To-Ontology Mapping not found' });
            });
            return undefined;
          }
        })
      );
  }

  submitOntologyToOntology(newTask: IOntologyToOntologyMappingPayload): Observable<ITerminologyMapping> {
    return this.http.post<ITerminologyMapping>(this.BASE_URL + 'ontology-to-ontology', newTask);
  }

  async downloadOntologyToOntologyResultsFile(taskId: string, fileName: string) {
    await this.downloadFile(fileName, this.BASE_URL + 'ontology-to-ontology/' + taskId + '/result/csv', 'csv');
  }
}
