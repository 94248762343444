import { Injectable } from '@angular/core';
import { MappingType } from '../enums/mapping-type';
import { IDataSettings, IIncludeItem, IOntologyToOntologySettings } from '../interfaces/i-data-settings';
import { IMappingSetting } from '../interfaces/i-mapping-setting';
import { IStepValidator } from '../interfaces/i-step-validator';
import { MappingSteps } from '../enums/mapping-steps';
import { IMappingResult } from '../interfaces/i-mapping-result';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { IMappingPayload } from '../interfaces/i-terminology-mapping-payload';
import { ITerminologyMapping as ITerminologyMapping } from '../interfaces/i-mapping-status';
import { IResultHeaders } from '../interfaces/i-result-headers';
import { IMappingOptions } from '../interfaces/i-mapping-options';
import { IFileSettings } from '../interfaces/i-file-settings';
import { IFileUploadResponse } from '../interfaces/i-file-upload-response';
import { OntologyService } from './ontology.service';
import { IOntologyToOntologyMappingPayload } from '../interfaces/i-ontology-to-ontology-mapping-payload';
import { IOntoToOntoMapping } from '../interfaces/i-onto-to-onto-mapping';
import { IMappingMethodology } from '../interfaces/i-mapping-methodology';

@Injectable({
  providedIn: 'root',
})
export class MappingService {
  private initializedSubject = new BehaviorSubject<boolean>(false);
  initialized$ = this.initializedSubject.asObservable();
  private _type!: MappingType;
  private _dataSettings!: IDataSettings;
  private _fileSettings!: IFileSettings;
  private _applySameOntologiesForEachTerm = false;
  private _settings!: IMappingSetting[];
  private _ontoToOntoMappingSettings: IOntologyToOntologySettings | undefined;
  private _options!: IMappingOptions;
  private ontologiesInitialized = false;
  private _mappingMethodologies!: IMappingMethodology[];
  private _resultHeaders: IResultHeaders = {};

  public static readonly MAX_TERMS_COUNT = 1000;
  public static readonly MAX_UNIQUE_TERMS_FOR_COLUMN_MAPPING = 50000;
  public readonly DEFAULT_MAX_RESULTS_PER_ONTOLOGY = 5;
  public readonly DEFAULT_SIMILARITY_SCORE_THRESHOLD = 0.1;

  constructor(
    private api: ApiService,
    private ontologyService: OntologyService
  ) {
    this.initialize();
    this.ontologyService.initialized$.subscribe({
      next: initialized => {
        this.ontologiesInitialized = initialized;
        this.isInitComplete();
      },
      error: err => this.initializedSubject.error(err),
    });
  }

  private initialize() {
    this.initializedSubject.next(false);
    this.fetchMethodologiesAndResultHeaders();
  }

  reset() {
    this.type = MappingType.UPLOAD_FILE;
    this.resetDataSettings();
    this.resetFileSettings();
    this.applySameOntologiesForEachTerm = false;
  }

  get type(): MappingType {
    return this._type;
  }

  set type(mappingType) {
    this._type = mappingType;
    this.resetOptions();
  }

  get mappingMethodologies(): IMappingMethodology[] {
    return this._mappingMethodologies;
  }

  get resultHeaders(): IResultHeaders {
    return this._resultHeaders;
  }

  get dataSettings(): IDataSettings {
    return this._dataSettings;
  }

  set dataSettings(newSettings: IDataSettings) {
    this._dataSettings = newSettings;
    this.settings = [];
    this.dataSettings.terms.forEach(term => {
      this.settings.push({
        termOrColumnName: term,
        ontologies: [],
        includeInMapping: true,
      });
    });
  }

  private resetDataSettings() {
    if (this.initializedSubject.value) {
      this.dataSettings = {
        terms: [],
      };
    }
    this.ontologyToOntologySettings = undefined;
  }

  get fileSettings() {
    return this._fileSettings;
  }

  set fileSettings(file: IFileSettings) {
    this._fileSettings = file;
    if (this.type === MappingType.UPLOAD_FILE) {
      this.settings = [];
      this.fileSettings.fileData?.data.forEach(column => {
        this.settings.push({
          termOrColumnName: column.columnHeader,
          uniqueTermsInColumnCount: column.terms.length,
          includeInMapping: column.ontologies.length > 0 ? true : false,
          ontologies: column.ontologies,
        });
      });
    }
  }

  get ontologyToOntologySettings() {
    return this._ontoToOntoMappingSettings;
  }

  set ontologyToOntologySettings(settings: IOntologyToOntologySettings | undefined) {
    this._ontoToOntoMappingSettings = settings;
  }

  private resetFileSettings() {
    this.fileSettings = {};
  }

  get mappingOptions(): IMappingOptions {
    return this._options;
  }

  set mappingOptions(options: IMappingOptions) {
    this._options = options;
  }

  private resetOptions() {
    if (this.initializedSubject.value) {
      const methodology = this.mappingMethodologies[0];
      const maxResultsPerOntology: number =
        this.type === MappingType.INDIVIDUAL_TERMS ? this.DEFAULT_MAX_RESULTS_PER_ONTOLOGY : 1;
      this.mappingOptions = {
        mappingMethodologyValue: methodology.value,
        maxResultsPerOntology,
        caseSensitive: undefined,
      };
    }
  }

  get applySameOntologiesForEachTerm(): boolean {
    return this._applySameOntologiesForEachTerm;
  }

  set applySameOntologiesForEachTerm(value: boolean) {
    this._applySameOntologiesForEachTerm = value;
  }

  get settings(): IMappingSetting[] {
    return this._settings;
  }

  set settings(mappingSettings: IMappingSetting[]) {
    this._settings = mappingSettings;
  }

  private getSelectedOntologies(): string[] {
    const allSelectedOntologies: string[] = [];
    this.settings.forEach(setting => {
      setting.ontologies.forEach(ontology => {
        if (!allSelectedOntologies.includes(ontology)) {
          allSelectedOntologies.push(ontology);
        }
      });
    });
    if (this.ontologyToOntologySettings?.target !== undefined) {
      allSelectedOntologies.push(this.ontologyToOntologySettings.target);
    }
    return allSelectedOntologies;
  }

  getAIUnavailableSelectedOntologies(): string[] {
    const unavailableOntologies: string[] = [];
    const aiAvailableOntologies = this.ontologyService.getAvailableOntologies({ label: 'ai', value: 'ai' });
    this.getSelectedOntologies().forEach(ontology => {
      if (aiAvailableOntologies.find(o => o.ontology_name === ontology) === undefined) {
        unavailableOntologies.push(ontology);
      }
    });
    return unavailableOntologies;
  }

  getIncludeItems(methodology: IMappingMethodology, mappingType: MappingType): IIncludeItem[] {
    const includeList: IIncludeItem[] = [];
    const excludeFileColumnHeaders = ['query term', 'ontology name'];
    this.resultHeaders[methodology.value]?.forEach(header => {
      if (!(mappingType === MappingType.UPLOAD_FILE && excludeFileColumnHeaders.includes(header))) {
        includeList.push({
          label: header,
          included: true,
        });
      }
    });
    return includeList;
  }

  isStepValid(stepIndex: number): IStepValidator {
    switch (stepIndex) {
      case MappingSteps.Data_Input: {
        switch (this.type) {
          case MappingType.INDIVIDUAL_TERMS: {
            if (this.dataSettings.terms.length === 0) {
              return {
                dataVaild: false,
                error: 'No Search Terms defined. Cannot proceed',
              };
            } else if (this.dataSettings.terms.length > MappingService.MAX_TERMS_COUNT) {
              return {
                dataVaild: false,
                error: 'Max terms exceeded. Please remove Terms to continue',
              };
            } else {
              return {
                dataVaild: true,
              };
            }
          }
          case MappingType.UPLOAD_FILE: {
            if (
              this.fileSettings.fileData?.data &&
              this.fileSettings.fileData.data.length > 0 &&
              this.fileSettings.fileUrl
            ) {
              return {
                dataVaild: true,
              };
            } else {
              return {
                dataVaild: false,
                error: 'No File has been loaded. Cannot proceed.',
              };
            }
          }
          case MappingType.ONTOLOGY_TO_ONTOLOGY: {
            if (this.ontologyToOntologySettings !== undefined) {
              return {
                dataVaild: true,
              };
            } else {
              return {
                dataVaild: false,
                error: 'Please input all fields. Cannot proceed.',
              };
            }
          }
          default: {
            return {
              dataVaild: true,
            };
          }
        }
      }
      case MappingSteps.Select_Ontology: {
        switch (this.type) {
          case MappingType.INDIVIDUAL_TERMS:
          case MappingType.UPLOAD_FILE: {
            if (this.settings.findIndex(column => column.includeInMapping === true) === -1) {
              return {
                dataVaild: false,
                error: 'Please include at least 1 column to continue',
              };
            }
            let dataValid = true;
            let error: string | undefined;
            let totalUniqueTerms = 0;
            this.settings.forEach(mapping => {
              if (mapping.includeInMapping && mapping.uniqueTermsInColumnCount) {
                totalUniqueTerms += mapping.uniqueTermsInColumnCount;
              }
              if (mapping.includeInMapping && mapping.ontologies.length === 0) {
                dataValid = false;
                error = 'Please select at least 1 ontology for each item to continue';
              }
            });
            if (totalUniqueTerms > MappingService.MAX_UNIQUE_TERMS_FOR_COLUMN_MAPPING) {
              dataValid = false;
              error =
                totalUniqueTerms +
                '/' +
                MappingService.MAX_UNIQUE_TERMS_FOR_COLUMN_MAPPING +
                ' Maximum Unique Terms. Please remove columns to continue';
            }
            return {
              dataVaild: dataValid,
              error,
            };
          }
          case MappingType.ONTOLOGY_TO_ONTOLOGY: {
            if (this.ontologyToOntologySettings?.target) {
              return {
                dataVaild: true,
              };
            } else {
              return {
                dataVaild: false,
                error: 'Please select a Target Ontology to proceed.',
              };
            }
          }
          default:
            return {
              dataVaild: true,
            };
        }
      }
      default:
        return {
          dataVaild: true,
        };
    }
  }

  uploadFile(file: File): Observable<IFileUploadResponse> {
    return this.api.uploadFile(file);
  }

  mapToOntologies(): Observable<ITerminologyMapping | IOntoToOntoMapping> {
    const payload: IMappingPayload = {
      task_name: this.mappingOptions.mappingMethodologyValue,
      terms_input_type: this.type,
      params: {
        limit: this.mappingOptions.maxResultsPerOntology,
        similarity_score_threshold: this.mappingOptions.similarityScoreThreshold,
        case_sensitive: this.mappingOptions.caseSensitive,
      },
      label: this.mappingOptions.jobTitle,
    };
    switch (this.type) {
      case MappingType.INDIVIDUAL_TERMS: {
        const mappingTerms: { term: string; ontologies: string[]; parent_iris?: string[] }[] = [];
        this.settings.forEach(term => {
          if (term.includeInMapping) {
            mappingTerms.push({
              term: term.termOrColumnName,
              ontologies: term.ontologies,
              parent_iris: term.parentClassIris,
            });
          }
        });
        payload.params.terms = mappingTerms;
        return this.api.submit(payload);
      }
      case MappingType.UPLOAD_FILE: {
        const termColumns: { column_header: string; ontologies: string[] }[] = [];
        this.settings.forEach(column => {
          if (column.includeInMapping) {
            termColumns.push({
              column_header: column.termOrColumnName,
              ontologies: column.ontologies,
            });
          }
        });
        payload.params.term_columns = termColumns;
        payload.params.file_url_path = this.fileSettings.fileUrl;
        return this.api.submit(payload);
      }
      case MappingType.ONTOLOGY_TO_ONTOLOGY: {
        if (
          payload.label &&
          this.ontologyToOntologySettings &&
          this.ontologyToOntologySettings.source &&
          // this.fileSettings.fileUrl &&
          // this.ontologyToOntologySettings.iriColumn &&
          payload.task_name &&
          this.ontologyToOntologySettings.target
        ) {
          const ontoToOntoPayload: IOntologyToOntologyMappingPayload = {
            label: payload.label,
            source_ontology: this.ontologyToOntologySettings.source,
            // input_file_url_path: this.fileSettings.fileUrl,
            // input_file_iri_column: this.ontologyToOntologySettings.iriColumn,
            tms_mapping_function: payload.task_name,
            target_ontology: this.ontologyToOntologySettings.target,
            similarity_score_threshold: payload.params.similarity_score_threshold
              ? payload.params.similarity_score_threshold
              : 0.1,
          };
          return this.api.submitOntologyToOntology(ontoToOntoPayload);
        } else {
          console.error('Cannot start mapping because of missing parameters');
          return throwError(() => 'Missing Required parameters to map Ontology To Ontology');
        }
      }
    }
  }

  getMapping(runId: string, type?: MappingType): Observable<ITerminologyMapping | IOntoToOntoMapping | undefined> {
    if (this.type === MappingType.ONTOLOGY_TO_ONTOLOGY || type === MappingType.ONTOLOGY_TO_ONTOLOGY) {
      return this.api.getOntologyToOntology(runId);
    }
    return this.api.getMapping(runId);
  }

  getTaskRequest(requestUrl: string): Observable<IMappingPayload | IOntologyToOntologyMappingPayload> {
    return this.api.getTaskRequest(requestUrl);
  }

  getResult(runId: string): Observable<IMappingResult> {
    return this.api.getResult(runId);
  }

  cancelTask(runId: string): Observable<ITerminologyMapping> {
    return this.api.cancelTask(runId);
  }

  private fetchMethodologiesAndResultHeaders() {
    this.api.fetchMappingMethodologies().subscribe({
      next: methodologyValues => {
        const methodologies: IMappingMethodology[] = [];
        methodologyValues.forEach(value => {
          methodologies.push(this.getMappingMethodology(value));
        });
        this._mappingMethodologies = methodologies;
        this.mappingMethodologies.forEach(type => {
          this.fetchResultHeaders(type.value);
        });
      },
      error: err => this.initializedSubject.error(err),
    });
  }

  private fetchResultHeaders(methodology: string) {
    this.api.fetchResultHeaders(methodology).subscribe({
      next: resultHeaders => {
        this._resultHeaders[methodology] = resultHeaders;
        this.isInitComplete();
      },
      error: err => this.initializedSubject.error(err),
    });
  }

  private isInitComplete() {
    const complete =
      this.ontologiesInitialized &&
      this.mappingMethodologies &&
      this.mappingMethodologies.length > 0 &&
      this.resultHeaders &&
      Object.keys(this.resultHeaders).length === this.mappingMethodologies.length;
    this.initializedSubject.next(complete);
    if (complete) {
      this.reset();
    }
  }

  formatResultsToIgxGrid(results: IMappingResult): unknown[] {
    return this.api.formatMappingResultsToJson(results);
  }

  getMappingMethodology(value: string): IMappingMethodology {
    if (value === 'fuzzy_plus') {
      return {
        value,
        label: 'Fuzzy+',
      };
    } else {
      return {
        value,
        label: value,
      };
    }
  }
}
