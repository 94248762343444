import { Injectable } from '@angular/core';
import { IColumnTerms, IParsedFile } from '../interfaces/i-parsed-file';
import { parse } from 'papaparse';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  parseCsv(file: File | null | undefined): Promise<IParsedFile> {
    return new Promise((resolved, failed) => {
      if (file?.type === 'text/csv') {
        parse<string[]>(file, {
          delimiter: ',',
          dynamicTyping: true,
          complete(results) {
            const data: IColumnTerms[] = [];
            let headers: string[] = [];
            const rows = results.data;
            rows.forEach((cells, rowIndex) => {
              if (rowIndex === 0) {
                headers = cells;
                headers.forEach(header => {
                  data.push({
                    columnHeader: header,
                    terms: [],
                    ontologies: [],
                  });
                });
              } else {
                cells.forEach((cell, colIndex) => {
                  if (colIndex < headers.length) {
                    const currentTerms = data[colIndex].terms;
                    if (cell && currentTerms.indexOf(cell) === -1 && cell.length > 0) {
                      data[colIndex].terms.push(cell);
                    }
                  }
                });
              }
            });
            resolved({
              data,
              totalTerms: FileService.getTotalTerms(data),
            });
          },
          error(error) {
            console.error('Pasring Error', error);
            failed(error.message);
          },
        });
      } else {
        failed('File format not supported');
      }
    });
  }

  private static getTotalTerms(data: IColumnTerms[]): number {
    let totalTerms = 0;
    data.forEach(column => {
      totalTerms += column.terms.length;
    });
    return totalTerms;
  }
}
