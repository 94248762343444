<div
  [nbSpinner]="loading"
  nbSpinnerStatus="primary"
  nbSpinnerMessage=""
  class="white-fill padding-1 border-radius-1 width-4">
  <div class="header-1">
    <div *ngIf="!errorMsg">Delete Mapping Rule</div>
    <div *ngIf="errorMsg" class="header-1">Error Deleting mapping rule ❌</div>
  </div>
  <div *ngIf="!errorMsg">
    Are you sure you want to <b>delete</b> your <b>{{ tmsRule.query_term }}</b> mapping rule?
  </div>
  <div class="margin-top-2">
    Ontology: <b>{{ tmsRule.ontology_name | uppercase }}</b>
  </div>
  <div class="grid-3-columns column-gap-1 margin-top-2 margin-bottom-3 text-center">
    <div>
      <div>Query Term</div>
      <div>
        <b>{{ tmsRule.query_term }}</b>
      </div>
    </div>
    <div><nb-icon icon="arrow-forward" status="primary" style="font-size: xx-large"></nb-icon></div>
    <div>
      <div>Maps To</div>
      <div>
        <b>{{ tmsRule.mapped_term.label }}</b>
      </div>
    </div>
  </div>
  <div *ngIf="errorMsg" class="text-center text-error">
    Error: <b>{{ errorMsg }}</b>
  </div>
  <div class="flex-x-reverse column-gap-1 margin-top-1">
    <button *ngIf="!errorMsg" nbButton (click)="deleteRule()" shape="round" status="danger" size="small">
      <nb-icon icon="trash-2-outline" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
      Delete Rule
    </button>
    <button nbButton (click)="close()" shape="round" status="primary" size="small" outline>dismiss</button>
  </div>
</div>
