<nb-layout>
  <nb-layout-header fixed class="box-shadow">
    <app-page-header></app-page-header>
  </nb-layout-header>

  <nb-layout-column>
    <router-outlet></router-outlet>
  </nb-layout-column>

  <nb-layout-footer>
    <div class="flex-x width-fit margin-inline-auto column-gap-2 text-small">
      <a [href]="userGuideLink" target="_blank">User Guide</a>
      <app-feedback-form></app-feedback-form>
    </div>
  </nb-layout-footer>
</nb-layout>
